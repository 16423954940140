export const  env={
  url:"http://127.0.0.1:8000/"
}

// export const backendUrl=`https://us-central1-joyomoney-a8630.cloudfunctions.net/joyMoney/api/v1/`;
// export const backendUrl=`http://localhost:8000/api/v1/`;
// export const backendUrl=`https://parvfinancebackend.vercel.app/api/v1/`;
// export const backendUrl=`https://loan2backend.vercel.app/api/v1`;
// export const backendUrl=`https://loan2backend-abhi-git-master-abhishek-kumars-projects-e0390462.vercel.app/api/v1`;
// export const backendUrl=`https://loan2backend-abhi.vercel.app/api/v1/`;

// export const baseUrl=`http://localhost:3000/`;
// export const backendUrl=`https://parvfinancebackend.vercel.app/api/v1`
// export const backendUrl=`https://parvfinancebackend.vercel.app/api/v1`






export const backendUrl=`https://loannode.onrender.com/api/v1/`