import axios from 'axios';
import React, { useState } from 'react';
import { backendUrl } from '../../../env';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const AddRemarks = () => {
    const [remark,setRemark]=useState();
    const {id}=useParams();
    const Navigate=useNavigate();

    const addRemark=async()=>{
        try {
            const res=await axios.put(`${backendUrl}/add_remark/${id}`,{remark});
            console.log("remark added",res);
            if(res?.status===200){
                toast.success(res?.data?.message);
                Navigate("/homeservices");
            }
        } catch (error) {
            console.log("Error when try to add remark",error);
        }
    }
    return (
        <div className='my-6 py-6 mx-auto w-25'>
            <h1>Add Remark</h1>

            <div class="" >
                <textarea 
                class="form-control" 
                placeholder="Remarks" 
                id="floatingTextarea" 
                height="600"
                onChange={(e)=>setRemark(e.target.value)}
                value={remark}
                >
                </textarea>
                {/* <label for="floatingTextarea">Remarks</label> */}
            </div>
            <div className=''>
                <button className='btn btn-primary' onClick={addRemark}>Add Remark</button>
            </div>
        </div>
    );
}

export default AddRemarks;
