import React, { useEffect, useState } from 'react';
import Sidebar from '../UserDashbord/Sidebar';
import { backendUrl } from '../../../env';
import axios from 'axios';
import OpenProfileModal from './OpenProfileModal';
import { toast } from 'react-toastify';

const Connectors = () => {
    const [connectors, setConnectors] = useState([]);
    const fetchConnectors = async () => {
            
        try {
            const res = await axios.get(`${backendUrl}/getallconnectorUser`);
            const data = res.data;
            // console.log(data);
            setConnectors(data?.user);
        } catch (error) {
            console.log("Error while fetching connectors", error);
        }
    }

    useEffect(() => {
       
        fetchConnectors();
    }, [])

    const ApproveConnector=async(id)=>{
        try {
            const res = await axios.put(`${backendUrl}/update_connector_status/${id}`);
            const data = res.data;
            // console.log(data);
            // setConnectors(data?.user);
            window.location.reload();
        } catch (error) {
            console.log("Error while fetching connectors", error);
        }
    }
    const RejectConnector=async(id)=>{
        try {
            const res = await axios.put(`${backendUrl}/reject_connector_status/${id}`);
            const data = res.data;
            // console.log(data);
            // setConnectors(data?.user);
            window.location.reload();
        } catch (error) {
            console.log("Error while fetching connectors", error);
        }
    }
    const deleteConnector=async(id)=>{
        try {
            const res = await axios.delete(`${backendUrl}/delete_connector/${id}`);
            const data = res.data;
            // console.log(data);
            // setConnectors(data?.user);
            fetchConnectors();
            toast.success("Delete User Successfully");
            // window.location.reload();
        } catch (error) {
            console.log("Error while fetching connectors", error);
        }
    }
    return (
        <div>
            <Sidebar>
                <div className="">
                    <h2 className='mx-4'>Connectors List</h2>
                    <table className="table table-striped mt-5">
                        <thead>
                            <tr>
                                <th>Sr.no</th>
                                {/* <th>Form Id</th> */}
                                <th>Name</th>
                                <th>Mobile Number</th>
                                <th>What's app Number</th>
                                {/* <th>Timestamp</th> */}
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <tr>
                                <td>1</td>
                                <td>sdjnswj</td>
                                <td>asdsmlk</td>
                                <td>sadmskd</td>
                                <td>dmxsd</td>
                                <td>
                                    <button className=' btn btn-primary mx-1'>Approved</button>
                                    <button className='btn btn-danger'>Reject</button>
                                </td>
                            </tr> */}
                            {
                                connectors && connectors.map((item, index) => (
                                    <tr key={item._id} >
                                        <td>{index + 1}</td>
                                        {/* <td>{item._id.slice(-6)}</td> */}
                                        <td>{item?.full_name}</td>
                                        <td>{item?.mobile_number}</td>
                                        <td>{item?.whats_app_number}</td>

                                        {/* <td>
                                            {console.log(item?.approved)}
                                            {item?.approved===true
                                                ? <button className=' btn btn-white py-1 px-2 text-success mx-1'>Approved</button>
                                                :
                                                <>
                                                     item?.approved===false ? <button className=' btn btn-white py-1 px-2 text-danger mx-1'>Rejected</button>:<>
                                                    <button 
                                                    className=' btn btn-primary mx-1 px-2 py-1'
                                                     onClick={()=>{ApproveConnector(item?._id)}}>Approve</button>
                                                    <button 
                                                    className='btn btn-danger px-2 py-1' 
                                                    onClick={()=>{RejectConnector(item?._id)}}>Reject</button>
                                                </>
                                                </>
                                               
                                                <></>:
                                                 
                                            }

                                        </td> */}
                                         <td className='d-flex gap-3'>
                                        {item?.approved === true ?
                                            <>
                                                <button className='btn btn-seconadry px-4 py-2 text-success'>Approved</button>
                                            </>
                                            :
                                            <>
                                                {item?.approved === false ?
                                                    <button className=' btn btn-white px-4 py-2 text-danger'>Rejected</button> 
                                                    :
                                                    <>
                                                        <button
                                                            className='btn py-1 btn-primary px-2'
                                                            onClick={() => ApproveConnector(item?._id)}>Approve</button>
                                                        <button
                                                            className='btn py-1 px-2 btn-danger'
                                                            onClick={() => RejectConnector(item?._id)}
                                                        >Reject</button>
                                                    </>
                                                }
                                                {/* <button
                                                    className='btn py-1 btn-primary px-2'
                                                    onClick={() => ApproveLoan(item?._id)}>Approve</button>
                                                <button 
                                                className='btn py-1 px-2 btn-danger'
                                                onClick={()=>rejectLoan(item?._id)}
                                                >Reject</button> */}
                                            </>
                                        }

                                    </td>
                                        {/* {
                                             <>
                                             {item?.approved === false ?
                                                 <button className=' btn btn-white px-4 py-2 text-danger'>Rejected</button> :
                                                 <>
                                                     <button
                                                         className='btn py-1 btn-primary px-2'
                                                         onClick={() => ApproveLoan(item?._id)}>Approve</button>
                                                     <button
                                                         className='btn py-1 px-2 btn-danger'
                                                         onClick={() => rejectLoan(item?._id)}
                                                     >Reject</button>
                                                 </>
                                             } */}
                                             {/* <button
                                                 className='btn py-1 btn-primary px-2'
                                                 onClick={() => ApproveLoan(item?._id)}>Approve</button>
                                             <button 
                                             className='btn py-1 px-2 btn-danger'
                                             onClick={()=>rejectLoan(item?._id)}
                                             >Reject</button> */}
                                         {/* </>
                                        } */}
                                        <td className=' d-flex gap-2'>
                                            <OpenProfileModal data={item} />
                                            <button 
                                            className='btn btn-danger px-2 py-1' 
                                            onClick={()=>{deleteConnector(item?._id)}}>Delete</button>
                                        </td>


                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>


            </Sidebar>
        </div>
    );
}

export default Connectors;
